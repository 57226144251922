import Select2Multiple from 'v-select2-multiple-component';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import MainLayout from "@/components/layout/admin/MainLayout.vue";
import CONFIG from "@/assets/js/config.js";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import axios from "axios";
import {
  required,
} from "vuelidate/lib/validators";
import store from '@/store'

export default {
  name: "checkoutEdit",
  components: {
    MainLayout,
    Select2Multiple,
    flatPickr,
    Loading,
  },

  data() {
    return {
      checkoutData: [],
      memberArr: null,
      categoryArr: null,
      bookArr: null,
      isError: false,
      submitted: false,
      isLoading: false,
      campusId: store.state.user.campus_master.campus_code,
      disabled: false,
      noDataMsg: CONFIG.NO_DATA_EXIST,
      noMemberMsg: '',
      noCategoryMsg: '',
      noBookMsg: '',
      dateRequired: true,
      selectedType: 2,
      returnStatusArr: CONFIG.RETURN_STATUS
    };
  },

  validations: {
    checkoutData: {
      library_member_id: {
        required,
      },
      category_id: {
        required
      },
      book_id: {
        required,
      },
      start_date: {
        required
      },
      end_date: {
        required
      },
      is_return: {
        required
      },
    },
  },

  methods: {
    getMember() {
      let $defaultSelectOption = [{
        'id': 0,
        'text': 'Select Member'
      }];
      axios.get("/member/getList", {
        params: {
          campus_id: this.$store.state.user.campus_id,
        }
      })
        .then(res => {
          if (res.data.data.length > 0) {
            this.memberArr = $defaultSelectOption.concat(res.data.data);
          } else {
            this.memberArr = $defaultSelectOption;
          }
          this.isLoading = false;
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    getCategory() {
      this.book_id = 0;
      let $defaultSelectOption = [{
        'id': 0,
        'text': 'Select Category'
      }];
      axios.get('/category/getList')
        .then(res => {
          if (res.data.data.length > 0) {
            this.categoryArr = $defaultSelectOption.concat(res.data.data);
          } else {
            this.categoryArr = $defaultSelectOption;
          }
          this.isLoading = false;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    getBookListByCategoryId(categoryId) {
      var request = {
        params: {
          category_id: categoryId,
          campus_id: this.$store.state.user.campus_id
        }
      }
      axios.get("/book/getList", request)
        .then((res) => {
          let $defaultSelectOption = [{
            'id': 0,
            'text': 'Select Book'
          }];
          if (res.data.data.length > 0) {
            this.bookArr = $defaultSelectOption.concat(res.data.data);
            this.noCategoryMsg = '';
            this.noBookMsg = '';
            this.isLoading = false;
          } else {
            this.bookArr = $defaultSelectOption;
            this.noCategoryMsg = 'There is no category.'
            this.noBookMsg = 'No Book for this campus.'
            this.isLoading = false;
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    changeCategoryEvent(categoryId) {
      this.checkoutData.category_id = 0;
      this.checkoutData.book_id = 0;
      this.getBookListByCategoryId(categoryId);
    },
    
    changeEvent(val, fieldName) {
      this.$set(this, fieldName, val);
      if (fieldName == "selectedType") {
        if (val == this.returnStatusArr.Yes) {
          this.dateRequired = true;
        } else {
          this.dateRequired = false;
        }
      }
    },
    
    getDetail() {
      axios.get(`checkout/detail/${this.$route.params.id}`)
        .then(res => {
          if (res.data) {
            this.checkoutData = res.data;
          }
          this.getBookListByCategoryId(this.checkoutData.category_id);
          this.isLoading = false;
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
          console.log("Checkout Detail API Error", error);
        })

    },

    update() {
      this.submitted = true;
      if (this.checkoutData.start_date && this.checkoutData.end_date && new Date(this.checkoutData.end_date) < new Date(this.checkoutData.start_date)) {
        this.isError = true;
      } else {
        this.isError = false;
      }
      if (this.checkoutData.is_return == this.returnStatusArr.Yes && !this.checkoutData.return_date) {
        this.dateRequired = true;
      } else {
        this.dateRequired = false;
      }
      if (this.$v.$invalid || this.isError || this.dateRequired) {
        return;
      }
      this.checkoutData.login_id = this.$store.state.user.id;
      axios.post(`checkout/update/${this.$route.params.id}`, this.checkoutData)
        .then(res => {
          if (res.data.success == true) {
            this.$notification.success("Checkout Form Updated Successfully", {
              timer: 3,
              position: "bottomCenter"
            });
            this.$router.push({
              name: "checkoutList"
            });
          } else {
            this.disabled = false;
            this.$notification.error('res.data.message', {
              timer: 3,
              position: "bottomCenter",
            });
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          if (error.response.status == 422) {
            this.$notification.error(error.response.data.message, {
              timer: 3,
              position: "bottomCenter",
            });
          }
          this.isLoading = false;
        })
    }
  },

  mounted() {
    this.isLoading = true;
    this.getDetail();
    this.getMember();
    this.getCategory();
  },
};
